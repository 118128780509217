import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken, deleteAuthToken } from '../util/request';
import { reset, setUser } from '../slices/userSlice';
import Auth from '../api/auth';
import { setCompany } from 'src/store';

export function useAuth() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [isLogginIn, setIsLogginIn] = useState(false);

  const login = useCallback(
    async (credentials) => {
      setIsLogginIn(true);
      try {
        const { user, token, company } = await Auth.login(credentials);
        setAuthToken(token);
        persistToken(token);
        setIsLogginIn(false);
        dispatch(setCompany(company));
        dispatch(setUser(user));
      } catch (error) {
        setIsLogginIn(false);
        persistToken(null);
        throw error;
      }
    },
    [dispatch, setIsLogginIn]
  );

  const logout = async () => {
    await Auth.logout();
    persistToken(null);
    deleteAuthToken();
    dispatch(reset());
  };

  const getPersistedToken = () => {
    return window.localStorage.getItem('authTK');
  };

  const persistToken = (token) => {
    if (token) {
      return window.localStorage.setItem('authTK', token);
    }

    window.localStorage.removeItem('authTK');
  };

  return {
    isLoggedIn,
    isLogginIn,
    login,
    logout,
    getPersistedToken,
    setAuthToken,
  };
}

export default useAuth;
