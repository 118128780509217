import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSession } from '../api/auth';

const initialState = {
  id: null,
  email: '',
  isLoggedIn: false,
  isLoadingUser: false,
};

export const fetchUser = createAsyncThunk('user/fetch', async () => {
  return await fetchSession();
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setUser: (state, { payload }) => {
      state.isLoggedIn = true;
      Object.assign(state, payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.isLoadingUser = false;
        Object.assign(state, payload.user);
      });
  },
});

export const { reset, setUser, } = userSlice.actions;

export default userSlice.reducer;
