import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from './store';

import { icons } from './assets/icons';

React.icons = icons;

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://609e5e167d60441391f67298ec58add3@o423517.ingest.sentry.io/5933179',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
