import { post, destroy, get } from '../util/request';

export const login = async (user) => {
  const res = await post(`/v1/users/sign_in`, { user });
  return res.data;
};

export const logout = async () => {
  const res = await destroy('/v1/users/sign_out');

  return res.data;
};

export const fetchSession = async () => {
  const res = await get(`/v1/session`);
  return res.data;
};

const Auth = { fetchSession, login, logout };

export default Auth;
